/**
 * A Typescript library which contains shared logic for all of my webserver projects.
 * @module
 */

/**
 * Publishes a highly-opinionated custom analytics event to the server.
 *
 * @example How to use:
 * ```ts
 * import { scitylana } from "@todd/webserver-base";
 *
 * document.addEventListener("DOMContentLoaded", scitylana);
 * ```
 */
export async function scitylana(e: Event): Promise<void> {
  e.preventDefault();

  const response: Response = await fetch("/api/v1/scitylana", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: new URLSearchParams({
      "user_agent": navigator.userAgent,
      "url": window.location.href,
      "referrer": document.referrer,
      "screen_width": window.innerWidth.toString(),
    }),
  });
  if (!response.ok) {
    console.error("Failed scitylana: ", response);
  }
}

// denoCacheMetadata={"headers":{"x-jsr-cache-id":"SFO-1d5601d9","last-modified":"Fri, 01 Mar 2024 08:10:51 GMT","etag":"\"9fce94f15a8e0cfd284efe8c55bacac2\"","x-goog-stored-content-length":"897","x-robots-tag":"noindex","content-length":"897","x-goog-generation":"1709280651748058","accept-ranges":"bytes","x-guploader-uploadid":"AHmUCY0sw8_l4lORKGjpNwLdFSSKNhH24pO6HxVeiv40rxsnLslaUiBLOJ2gVQIiLeJWJdFag0M","x-goog-hash":"crc32c=0phhaQ==,md5=n86U8VqODP0oTv6MVbrKwg==","x-goog-metageneration":"1","expires":"Sun, 02 Nov 2025 22:29:59 GMT","via":"1.1 google","x-goog-stored-content-encoding":"identity","access-control-allow-origin":"*","x-goog-storage-class":"STANDARD","x-jsr-cache-status":"miss","server":"UploadServer","cross-origin-resource-policy":"cross-origin","x-content-type-options":"nosniff","date":"Sat, 02 Nov 2024 22:29:59 GMT","content-type":"text/typescript","content-security-policy":"default-src 'none'; script-src 'none'; style-src 'none'; img-src 'none'; font-src 'none'; connect-src 'none'; frame-src 'none'; object-src 'none'; frame-ancestors 'none'; sandbox; form-action 'none';","cache-control":"public, max-age=31536000, immutable","alt-svc":"h3=\":443\"; ma=2592000,h3-29=\":443\"; ma=2592000","access-control-expose-headers":"*"},"url":"https://jsr.io/@todd/webserver-base/0.0.1/ui/static/script/mod.ts","time":1730586599}